import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import dayjs from "dayjs";
import uniqid from "uniqid";
import { isArray, isEmpty } from "lodash";
import { navigate } from "@reach/router";

import styles from "./PostItem.module.scss";

const propTypes = {
	className: PropTypes.object,
	path: PropTypes.string,
	tags: PropTypes.array,
	title: PropTypes.string,
	heroImage: PropTypes.string,
	author: PropTypes.string,
	date: PropTypes.string,
	readTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
	className: null,
	path: null,
	tags: null,
	title: null,
	heroImage: null,
	author: null,
	date: null,
	readTime: null,
};

function PostItem({ className, path, tags, heroImage, title, author, date, readTime }) {
	return (
		<Link to={path} className={`${styles.wrapper__main} ${className}`}>
			<div className={styles.image__wrapper}>
				<img
					src={heroImage ? heroImage : "/img/blog-placeholder.jpg"}
					alt={title}
					className={styles.image__el}
				/>
			</div>

			<div className={styles.content__wrapper}>
				{isArray(tags) && !isEmpty(tags) && (
					<div className={styles.content__tagWrapper}>
						{tags.map((t) => {
							return (
								<div
									key={uniqid()}
									onClick={(evt) => {
										evt.preventDefault();
										navigate(`?tag=${t.toLowerCase()}`);
									}}
									className={styles.content__tagItem}
									role="presentation"
								>
									{t}
								</div>
							);
						})}
					</div>
				)}

				<h1 className={styles.content__title}>{title}</h1>
				<p className={styles.content__author}>Written by {author}</p>
				<div className={styles.content__date}>
					Posted on {dayjs(date).format("MMMM D, YYYY")} {readTime}mins read
				</div>
			</div>
		</Link>
	);
}

PostItem.propTypes = propTypes;
PostItem.defaultProps = defaultProps;

export default PostItem;
