import React, { PureComponent } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import BlogPostingsLayout from "../layouts/BlogPostingsLayout/BlogPostingsLayout";
import { parseQS } from "../utils";

const propTypes = {
	data: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

class Blog extends PureComponent {
	state = {
		postData: [],
	};

	componentDidMount() {
		const {
			data: {
				allFile: { edges: postData },
			},
			location: { search },
		} = this.props;

		const query = parseQS(search, "tag");

		if (query === undefined || query[0] === "") {
			this.setState({
				postData,
			});
		} else {
			this.filterPosts();
		}
	}

	componentDidUpdate(prevProps) {
		const {
			location: { search },
		} = this.props;

		if (search !== prevProps.location.search) {
			this.filterPosts();
		}
	}

	filterPosts() {
		const {
			data: {
				allFile: { edges: postData },
			},
			location: { search },
		} = this.props;

		const query = parseQS(search, "tag");

		const filteredPostsData = [];

		postData.forEach((postDataItem) => {
			const {
				node: {
					childMarkdownRemark: {
						frontmatter: { tags },
					},
				},
			} = postDataItem;

			if (query === undefined || query[0] === "" || !query) {
				filteredPostsData.push(postDataItem);
			} else if (tags?.map((t) => t.toLowerCase()).includes(query[0].toLowerCase())) {
				filteredPostsData.push(postDataItem);
			}
		});

		this.setState({
			postData: filteredPostsData,
		});
	}

	render() {
		const { postData } = this.state;

		return <BlogPostingsLayout data={postData} />;
	}
}

export const query = graphql`
	query {
		allFile(
			filter: { relativeDirectory: { eq: "blog" } }
			sort: { order: DESC, fields: childMarkdownRemark___frontmatter___date }
		) {
			edges {
				node {
					id
					name
					childMarkdownRemark {
						id
						frontmatter {
							title
							blogAuthor
							date
							readTime
							heroImage
							tags
						}
						fields {
							slug
						}
					}
				}
			}
		}
	}
`;

Blog.propTypes = propTypes;
export default Blog;
