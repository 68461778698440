import React from "react";
import PropTypes from "prop-types";
import { isArray, isEmpty } from "lodash";
import uniqid from "uniqid";

import PostItem from "../../components/Blog/PostItem/PostItem";

import styles from "./BlogPostingsLayout.module.scss";

const propTypes = {
	data: PropTypes.array,
};

const defaultProps = {
	data: null,
};

function BlogPostingsLayout({ data }) {
	return (
		<section className={styles.wrapper__main}>
			<h1 className={styles.mastheadTitle}>BLOG</h1>

			{isArray(data) &&
				!isEmpty(data) &&
				data.map((item) => {
					const {
						node: {
							childMarkdownRemark: {
								fields: { slug },
								frontmatter: { blogAuthor: author, date, heroImage, readTime, title, tags },
							},
						},
					} = item;

					return (
						<PostItem
							key={uniqid()}
							tags={tags}
							path={slug}
							title={title}
							heroImage={heroImage}
							author={author}
							date={date}
							readTime={readTime}
						/>
					);
				})}
		</section>
	);
}

BlogPostingsLayout.propTypes = propTypes;
BlogPostingsLayout.defaultProps = defaultProps;

export default BlogPostingsLayout;
